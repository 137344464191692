import React from 'react';
import { withLayout } from '../../containers/Layout';
import { Product } from '@felicetteapp/types';
import { useApi } from '../../hooks/useApi';
import { Spinner } from '../../components/Spinner';
import { Link, Product as PaypalProduct } from '../../../pseudoPaypalTypes';
import { ListProducts } from '../../containers/Products/ListProducts';

const PageProductsBase = () => {
  const { fetching, response, error } = useApi<{ products: Array<Product> }>(
    'admin/products',
    { method: 'get' },
  );
  console.log({ response });
  const {
    fetching: paypalFetching,
    error: paypalError,
    response: paypalResponse,
  } = useApi<{
    links: Array<Link>;
    products: Array<PaypalProduct>;
  }>('paypal/catalogs/products', {
    method: 'get',
  });
  return (
    <>
      {fetching || paypalFetching ? (
        <Spinner />
      ) : error || paypalError ? (
        <div className="bg-red-900 px-2 py-2">ops!</div>
      ) : (
        <ListProducts
          products={response.data.products}
          paypalProducts={paypalResponse.data.products}
        />
      )}
    </>
  );
};
const PageProducts = React.memo(PageProductsBase);
export default () => withLayout({ title: 'Products' }, <PageProducts />);
