import React from 'react';
import { Product } from '@felicetteapp/types';
import { Product as paypalProducts } from '../../../../pseudoPaypalTypes';
import { Button } from '../../../components/Button';
import { ListProductsItem } from './Item';
type ListProductsProps = {
  products: Array<Product>;
  paypalProducts: Array<paypalProducts>;
};
const ListProductsBase: React.FC<ListProductsProps> = ({
  products,
  paypalProducts,
}) => {
  return (
    <div className="overflow-hidden">
      <header className="py-2 grid grid-cols-5 gap-x-2">
        <span>id</span>
        <span className="col-span-2">info</span>
        <span>status on paypal</span>
      </header>
      {products.map((product) => (
        <ListProductsItem data={product} />
      ))}
      {paypalProducts
        .filter(
          (paypalProduct) =>
            products.map((prod) => prod.id).indexOf(paypalProduct.id) < 0,
        )
        .map((product) => (
          <ListProductsItem paypalData={product} />
        ))}
    </div>
  );
};
export const ListProducts = React.memo(ListProductsBase);
