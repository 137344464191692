import React from 'react';
import { Product } from '@felicetteapp/types';
import { Product as paypalProduct } from '../../../../../pseudoPaypalTypes';
type ListProductsItemProps = {
  paypalData?: paypalProduct;
  data?: Product;
};
const ListProductsItemBase: React.FC<ListProductsItemProps> = ({
  paypalData,
  data,
}) => {
  return (
    <div className="py-1 grid grid-cols-5 gap-x-2">
      {paypalData ? (
        <>
          <div className="truncate">{paypalData.id}</div>
          <div className="col-span-2">
            <div className="grid grid-cols-3 gap-x-2">
              <span>x</span>
              <div className="col-span-2">
                <strong>{paypalData.name}</strong>
                <p>{paypalData.description}</p>
              </div>
            </div>
          </div>
          <div>
            <span className="bg-red-900 p-1 block w-full">
              Doesn't exist on Felicette's database
            </span>
          </div>
          <div></div>
        </>
      ) : data ? (
        <>
          <div className="truncate">{data.id}</div>
          <div className="col-span-2">
            <div className="grid grid-cols-3 gap-x-2">
              {data.image_url ? <img src={data.image_url} /> : <span>x</span>}
              <div className="col-span-2">
                <strong>{data.name}</strong>
                <p>{data.description}</p>
              </div>
            </div>
          </div>
          <div>
            {data.paypal.status === 'CREATED' ? (
              <span className="bg-green-900 p-1 block w-full">created</span>
            ) : data.paypal.status === 'WAITING' ? (
              <span className="bg-gray-800 p-1 block w-full">processing</span>
            ) : (
              <span className="bg-red-900 p-1 block w-full">error</span>
            )}
          </div>
          <div></div>
        </>
      ) : null}
    </div>
  );
};
export const ListProductsItem = React.memo(ListProductsItemBase);
